<template>
  <div class="mark page-mobile">
    <div class="content">
      <div
        v-for="(item, i) in list"
        :key="i"
        :class="'logo area-' + i"
        @click="btnProduct(item)"
      >
        <img :src="item.photo | urlImage" alt="logo-1" />
      </div>

      <div class="bar"></div>

      <!-- <div class="logo" @click="btnProduct('skinUp')">
        <img src="img/home/logo-2.png" alt="logo-2" />
      </div> -->
    </div>

    <Product :dialog.sync="dialog" :item.sync="dataMark" />
  </div>
</template>

<script>
import Product from "../product/product.vue";
import MarkService from "../../../../core/services/mark-services";
import MarkStorage from "../../../../core/storage/mark-storage";

export default {
  name: "Mark",
  data: () => ({
    dialog: false,
    type: "",
    list: {},
    dataMark: {},
  }),
  components: {
    Product,
  },
  created() {
    this.getMarkStorage();
    this.getMarkList();
  },
  methods: {
    btnProduct(value) {
      this.dialog = true;
      this.dataMark = value;
    },
    async getMarkStorage() {
      const { markAll } = MarkStorage();
      this.list = await markAll();
    },
    getMarkList() {
      const { getMarkAll } = MarkService();
      const { markAdd } = MarkStorage();
      getMarkAll().then((resp) => {
        const response = resp.data;
        if (response.code === 200) {
          response.data.map((item) => {
            markAdd(item);
          });
          this.getMarkStorage();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .content {
    display: grid;
    grid-template-rows: 0.5fr 1fr 1fr;
    grid-template-areas: "area0" "bar" "area1";

    .area-0 {
      grid-area: area0;
    }

    .area-1 {
      grid-area: area1;
    }

    .bar {
      grid-area: bar;
      border-top: 2px solid #b8babb;
      margin: auto;
      width: 100%;
    }

    .logo {
      padding: 10px;
      cursor: pointer;

      img {
        width: 18rem;
      }
    }
  }
}
</style>
