import axios from "axios";

const MarkServices = () => {
  const getMarkAll = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/marks`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const saveMark = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/marks`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateMark = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/marks/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const deleteMarkId = async (id) => {
    return await axios
      .delete(`${process.env.VUE_APP_ROOT_API}/marks/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    getMarkAll,
    saveMark,
    updateMark,
    deleteMarkId,
  };
};

export default MarkServices;
