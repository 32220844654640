import axios from "axios";

const ProductServices = () => {
  const getProductAll = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/product`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const getByIdMark = async (id) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/product/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const saveProduct = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/product`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateProduct = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/product/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateRepayment = async (data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/product/repayment`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const deleteProductId = async (id) => {
    return await axios
      .delete(`${process.env.VUE_APP_ROOT_API}/product/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const productStokById = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/product/stock/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    getProductAll,
    getByIdMark,
    saveProduct,
    updateProduct,
    updateRepayment,
    deleteProductId,
    productStokById,
  };
};

export default ProductServices;
