const getCleanedString = (cadena) => {
  // Lo queremos devolver limpio en minusculas
  cadena = cadena.toLowerCase();

  // Quitamos acentos y 'ñ'. Fijate en que va sin comillas el primer parametro
  cadena = cadena.replace(/á/gi, "a");
  cadena = cadena.replace(/é/gi, "e");
  cadena = cadena.replace(/í/gi, "i");
  cadena = cadena.replace(/ó/gi, "o");
  cadena = cadena.replace(/ú/gi, "u");
  cadena = cadena.replace(/ñ/gi, "n");
  return cadena;
};

const useSearch = ({ data, search, modulo }) => {
  if (!search) {
    return data;
  }

  if (data) {
    return data.filter((res) => {
      let datos;
      switch (modulo) {
        case "client":
          datos =
            getCleanedString(
              `${res.dni} 
                ${res.name} 
                ${res.lastName}
                ${res.email} ${res.seller?.name} ${res.seller?.lastName}`
            )
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1;
          break;
        case "product":
          datos =
            getCleanedString(`${res.code} ${res.name} ${res.price}`)
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1;
          break;
        case "order":
          datos =
            getCleanedString(
              `${res.code} 
                ${res.client.dni} 
                ${res.client.name} 
                ${res.client.lastName} 
                ${res.seller.name} 
                ${res.seller.lastName}`
            )
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1;
          break;
        default:
          return false;
      }
      return datos;
    });
  }
};

export default useSearch;
